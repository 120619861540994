import type { IconProps } from "~/components/types";

export const CheckboxCheckedIcon = ({
  fill = "none",
  size = 32,
  fillBox = "black",
  sizeBox = "24",
  className,
  ...props
}: IconProps & { fillBox?: string; sizeBox?: string }) => (
  <svg
    style={{ borderRadius: "5px", backgroundColor: fillBox }}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check_small">
      {/* if we have this mask, in firefox we don't see the white tick*/}
      {/* <g mask="url(#mask0_8975_5687)"> */}
      <path
        id="check_small_2"
        d="M10 16.4L6 12.4L7.4 11L10 13.6L16.6 7L18 8.4L10 16.4Z"
        fill="white"
      />
      {/* </g> */}
    </g>
  </svg>
);
